import React from "react"
import Layout from "../components/Layout/layout"
import Wrapper from "../components/Layout/Wrapper/wrapper";
import pageNotFound from "../images/logos/illust_404-passport.svg"
import { Link } from "gatsby"
import Panel1 from "../components/Layout/Panel1/panel1";


const NotFoundPage = () => (
  <Layout>
    <Wrapper page404>
      <Panel1 
        image={pageNotFound}
        title="ERROR 404"
        subtitle="Sorry, the page you are looking for is not available"
        buttonLabel="Go to Homepage">
      </Panel1>
    </Wrapper>
  </Layout>
)

export default NotFoundPage;