import React from "react";
import "./panel1.scss";
import { Title } from "../Title/title";
import { Subtitle } from "../Subtitle/subTitle";
import Wrapper from "../Wrapper/wrapper";
import { Link } from "gatsby";
import { Button } from "../Buttons/buttons";


const Panel1 = props => {


  return (
    <div className="panel1">
        <img src={props.image} alt="logo"/>
        <Title hero content={props.title}></Title>
        <Subtitle big content={props.subtitle}></Subtitle>
        <Wrapper classNames={`buttons_wrapper`}>
          <Button link="/" primary label={props.buttonLabel}></Button>
        </Wrapper>
    </div>
  )
}

export default Panel1;